import { DateTime } from 'luxon';

export function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === name + '=') {
                cookieValue = decodeURIComponent(
                    cookie.substring(name.length + 1),
                );
                break;
            }
        }
    }
    return cookieValue;
}

export function debounce(func, timeout = 300) {
    /*
      // Example usage:
      const setFilters = debounce((e) => {
        table.setFilter(e.target.name, "=", e.target.value);
      }, 300);
      input.addEventListener("change", function (e) {
        setFilters(e);
      });
  */
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
}

export function toggleAreaExpanded(selector) {
    let element;
    if (typeof selector === 'string') {
        element = document.querySelector(selector);
    } else if (typeof selector === 'object') {
        element = selector;
    }
    !(element.getAttribute('aria-expanded') == 'true')
        ? element.setAttribute('aria-expanded', 'true')
        : element.setAttribute('aria-expanded', 'false');
}

export function handleMobileMenu() {
    const menu_btn = document.getElementById('mobile-menu-btn');
    const menu = document.getElementById('main-nav');

    menu_btn.addEventListener('click', (e) => {
        e.preventDefault();
        toggleAreaExpanded(menu_btn);
        menu.classList.toggle('opened');
    });

    hideOnClickOutside(menu, menu_btn);
}

const isVisible = (elem) =>
    !!elem &&
    !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);
const isActive = (element, parent) => {
    return (
        element.classList.contains('opened') ||
        element.classList.contains('active') ||
        parent.classList.contains('opened') ||
        parent.classList.contains('active')
    );
};

export function hideOnClickOutside(element, parent) {
    const outsideClickListener = (event) => {
        if (
            !parent.contains(event.target) &&
            !element.contains(event.target) &&
            isVisible(element)
        ) {
            if (
                isActive(element, parent) &&
                parent.querySelector('[aria-expanded]')
            ) {
                toggleAreaExpanded(parent.querySelector('[aria-expanded]'));
            }
            if (
                isActive(element, parent) &&
                parent.hasAttribute('aria-expanded')
            ) {
                toggleAreaExpanded(parent);
            }
            element.classList.remove('opened');
            element.classList.remove('active');
            parent.classList.remove('opened');
            parent.classList.remove('active');
        }
    };
    document.addEventListener('click', outsideClickListener);
}

export function initializeClock() {
    const clockElement = document.querySelector('.clock');
    function updateClock() {
        const currentTime = DateTime.now();
        clockElement.textContent = currentTime.toFormat('HH:mm');
    }

    updateClock();
    setInterval(updateClock, 1000);
}

export async function sendDeleteRequest(url) {
    return fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFTOKEN': getCookie('csrftoken'),
        },
    });
}

export async function sendPostRequest(url, data) {
    return fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFTOKEN': getCookie('csrftoken'),
        },
    });
}

export async function sendPatchRequest(url, data) {
    return fetch(url, {
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFTOKEN': getCookie('csrftoken'),
        },
    });
}

export default {
    getCookie,
    debounce,
    toggleAreaExpanded,
    handleMobileMenu,
    hideOnClickOutside,
    initializeClock,
    sendDeleteRequest,
    sendPatchRequest,
    sendPostRequest,
};
