import {
    debounce,
    getCookie,
    sendDeleteRequest,
    sendPostRequest,
    sendPatchRequest,
} from '../utils.js';
import Choices from 'choices.js';
import { addAlert, genericErrorAlert } from '../alerts';
import Sortable from 'sortablejs';
import { deletePrompt, postPrompt } from '../prompts';

const manualId = document.querySelector('#manualId')?.value;
const autosaveLabel = document.querySelector(
    '#autosaveIndicator .autosave-label',
);
const autosaveIcon = document.querySelector(
    '#autosaveIndicator .autosave-icon',
);

function startSave() {
    autosaveLabel.textContent = gettext('zapisywanie');
    autosaveIcon.classList.add('saving');
}

function endSave() {
    autosaveLabel.textContent = gettext('autozapis');
    autosaveIcon.classList.remove('saving');
    autosaveIcon.classList.remove('error');
}

function errorSave() {
    autosaveLabel.textContent = gettext('błąd zapisu!');
    autosaveIcon.classList.add('error');
}

async function onFormDelete(btn) {
    startSave();
    const url = btn.getAttribute('data-url');
    const form = btn.closest('.manual-form');
    const response = await sendDeleteRequest(url);
    const data = await response.json();
    form.remove();
    endSave();
}

async function onFormAdd(btn) {
    startSave();
    const url = btn.dataset.url;
    const prefix = btn.dataset.prefix;

    const orderInputs = document.querySelectorAll(
        `input[name^="${prefix}-"][name$="-order"]`,
    );
    const orderValues = Array.from(orderInputs).map((input) =>
        parseInt(input.value, 10),
    );
    const newOrder = orderValues.length > 0 ? Math.max(...orderValues) + 1 : 1;

    const response = await sendPostRequest(
        `${url}?prefix=${prefix}-${newOrder}`,
        {},
    );
    const data = await response.json();

    const container = document.querySelector(btn.dataset.container);
    container.insertAdjacentHTML('beforeend', data.form);
    const newForm = container.querySelector('form:last-child');
    setupFormListeners(newForm);
    newForm.querySelectorAll('select').forEach((select) => {
        initializeSelect(select);
    });
    endSave();
}
export function setupFormListeners(form) {
    const url = form.dataset.patch;
    const prefix = `${form.dataset.prefix}-`;

    const makeUpdateRequest = debounce(async (field) => {
        try {
            const data = { [field.name.replace(prefix, '')]: field.value };
            await sendPatchRequest(url, data);
            endSave();
        } catch (error) {
            errorSave();
        }
    }, 50);

    form.querySelectorAll('input, select, textarea').forEach((field) => {
        field.addEventListener('change', (event) => {
            startSave();
            makeUpdateRequest(event.target);
        });
    });

    form.querySelectorAll('.delete-form').forEach((btn) => {
        btn.addEventListener('click', () => onFormDelete(btn));
    });

    form.addEventListener('submit', function (e) {
        e.preventDefault();
    });
}

export function initializeFormsets(rootElement) {
    rootElement.querySelectorAll('.add-form').forEach((btn) => {
        btn.addEventListener('click', () => onFormAdd(btn));
    });
    rootElement.querySelectorAll('form.manual-form').forEach((form) => {
        manuals.setupFormListeners(form);
    });
}

export function initializeSelect(rootElement) {
    let element;
    if (typeof rootElement === 'string') {
        element = document.querySelector(selector);
    } else if (typeof rootElement === 'object') {
        element = rootElement;
    }
    new Choices(element, { shouldSort: false });
}

export function initializePhotoLayout(layoutRootElement) {
    const root = layoutRootElement;
    const select = layoutRootElement.querySelector('.layout-select');
    const photos = layoutRootElement.querySelector('.layout-photos');
    const layoutApi = layoutRootElement.dataset.layoutApi;
    const setPhotoApi = layoutRootElement.dataset.photoApi;

    select.classList.contains('hidden')
        ? root.classList.add('clear-root')
        : null;

    layoutRootElement
        .querySelector('#changeLayout')
        .addEventListener('click', async function (e) {
            root.classList.remove('clear-root');
            select.classList.remove('hovered');
            startSave();
            const data = {
                layout: null,
            };
            const response = await sendPatchRequest(layoutApi, data);
            if (response.status === 200) {
                photos.classList.add('hidden');
                select.classList.remove('hidden');
                endSave();
            } else {
                errorSave();
            }
        });

    layoutRootElement
        .querySelector('#changeLayout')
        .addEventListener('mouseover', (e) => {
            if (
                !select.classList.contains('hidden') ||
                e.target.classList.contains('no-mouseover')
            ) {
                return;
            } else {
                select.classList.add('hovered');
            }
        });

    root.addEventListener('mouseleave', () => {
        select.classList.remove('hovered');
    });

    select.querySelectorAll('.layout').forEach((layoutElement) => {
        layoutElement.addEventListener('click', async (e) => {
            !root.classList.contains('clear-root')
                ? root.classList.add('clear-root')
                : null;
            select.classList.remove('hovered');
            startSave();
            const data = {
                layout: layoutElement.dataset.value,
            };
            const response = await sendPatchRequest(layoutApi, data);
            if (response.status === 200) {
                endSave();
                select.classList.add('hidden');
                photos.querySelector('.layout').className =
                    layoutElement.className;
                photos.classList.remove('hidden');
            } else {
                genericErrorAlert();
                errorSave();
            }
        });
    });

    async function handleFileUpload(file, slot) {
        startSave();
        const slotNumber = slot.dataset.number;
        const dropzone = slot.querySelector('.fileupload-container ');
        const image = slot.querySelector('img');

        const formData = new FormData();
        formData.append('file', file);

        const uploadResponse = await fetch(`/api/files/upload/`, {
            method: 'POST',
            headers: {
                'X-CSRFToken': getCookie('csrftoken'),
            },
            body: formData,
        });
        if (uploadResponse.status === 200) {
            const fileData = await uploadResponse.json();
            dropzone.classList.add('hidden');
            image.classList.remove('hidden');
            image.src = URL.createObjectURL(file);

            const data = {
                photo_id: fileData.id,
                slot: slotNumber,
            };
            const response = await sendPostRequest(setPhotoApi, data);

            if (response.status === 200) {
                endSave();
            } else {
                errorSave();
            }
        } else {
            errorSave();
        }
    }

    photos.querySelectorAll('.layout .slot').forEach((slot) => {
        const input = slot.querySelector('input');
        const dropzone = slot.querySelector('.fileupload-container ');
        slot.querySelector('.fileupload-container').addEventListener(
            'click',
            function () {
                input.click();
            },
        );

        slot.querySelector('img').addEventListener('click', function () {
            input.click();
        });

        dropzone.addEventListener('dragover', function (e) {
            e.stopPropagation();
            e.preventDefault();
            e.dataTransfer.dropEffect = 'copy';
            dropzone.classList.add('dragover');
        });

        dropzone.addEventListener('dragenter', function (e) {
            e.stopPropagation();
            e.preventDefault();
            dropzone.classList.add('dragover');
        });

        dropzone.addEventListener('dragleave', function (e) {
            e.stopPropagation();
            e.preventDefault();
            dropzone.classList.remove('dragover');
        });

        dropzone.addEventListener('dragend', function (e) {
            e.stopPropagation();
            e.preventDefault();
            dropzone.classList.remove('dragover');
        });

        dropzone.addEventListener('drop', (e) => {
            e.stopPropagation();
            e.preventDefault();
            dropzone.classList.remove('dragover');

            handleFileUpload(e.dataTransfer.files[0], slot);
        });

        input.addEventListener('change', function (e) {
            handleFileUpload(this.files[0], slot);
        });
    });
}

export function initializeBannerForm(bannerRootElement) {
    const addBanner = bannerRootElement.querySelector('.add-banner');
    const removeBanner = bannerRootElement.querySelector('.remove-banner');
    const hasBanner = bannerRootElement.querySelector('input[id^=id_has]');

    addBanner.addEventListener('click', function () {
        bannerRootElement.classList.add('active');
        hasBanner.value = 'True';
        hasBanner.dispatchEvent(new Event('change'));
    });

    removeBanner.addEventListener('click', function () {
        bannerRootElement.classList.remove('active');
        hasBanner.value = 'False';
        hasBanner.dispatchEvent(new Event('change'));
    });
}

export function initializeStepSelect() {
    const select = document.querySelector('#stepSelector');
    select.addEventListener('change', function () {
        window.location.href = select.value;
    });
}

export function initializeOrdering(element) {
    const url = element.dataset.orderUrl;
    async function onMoveAction(evt) {
        startSave();
        const response = await sendPostRequest(
            url,
            sortableInstance.toArray().map(Number),
        );
        if (response.status === 200) {
            endSave();
        } else {
            errorSave();
        }
    }

    const sortableInstance = new Sortable(element, {
        animation: 150,
        forceFallback: true,
        handle: '.ordering-handle',
        draggable: '.draggable',
        dataIdAttr: 'data-id',
        onEnd: async function (evt) {
            await onMoveAction(evt);
        },
    });
}

export function initializeDeleteButtons(rootElement, callback) {
    rootElement.querySelectorAll('.delete-prompt').forEach((btn) => {
        btn.addEventListener('click', function () {
            deletePrompt({
                options: {
                    text: gettext(
                        `Czy na pewno chcesz usunąć ${btn.dataset.label}?`,
                    ),
                    confirmText: gettext('Usuń'),
                },
                url: btn.dataset.url,
                successAction: function (response) {
                    if (callback) callback(btn);
                    if (btn.dataset.remove) {
                        btn.closest(btn.dataset.remove).remove();
                    }
                },
            });
        });
    });
}

export function initializePublishButton() {
    const btn = document.querySelector('#publishButton');
    btn.addEventListener('click', function () {
        postPrompt({
            options: {
                title: gettext(
                    'Czy na pewno chcesz opublikować tę wersję instrukcji?',
                ),
                text: gettext(
                    'Ta wersja stanie się aktywna, a poprzednia zostanie zarchiwizowana. Następne ewentualne zmiany będą wymagały utworzenia kolejnej wersji instrukcji.',
                ),
                confirmText: gettext('Publikuj'),
            },
            url: btn.dataset.url,
            successAction: function (response) {
                window.location.href = btn.dataset.redirect;
            },
        });
    });
}
