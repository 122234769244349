export default function (cell, formatterParams, onRendered) {
    const button = document.createElement('button');
    button.className = 'btn-table-menu collapse';
    button.innerHTML = '<span class="icon-arrow_down"></span>'; //TODO add icons
    const that = this;

    button.addEventListener('click', function (e) {
        e.stopPropagation();
        const rowElement = cell.getRow().getElement();
        const nestedTableContainer = rowElement.querySelector(
            '.nested-table-container',
        );
        nestedTableContainer.classList.toggle('hidden');
        button.classList.toggle('active');
    });
    return button;
}
