import { sendPatchRequest, sendPostRequest } from '../utils';
import { genericErrorAlert } from '../alerts';

export function initializeDisassemblyActions() {
    function checkAllConfirmed() {
        const allConfirmed = Array.from(
            document.querySelectorAll('.disassembly-action'),
        ).every((action) => action.dataset.confirmed === 'True');
        if (allConfirmed) {
            document
                .querySelectorAll('.actions-confirmed')
                .forEach((element) => {
                    element.classList.remove('hidden');
                });
        } else {
            document
                .querySelectorAll('.actions-confirmed')
                .forEach((element) => {
                    element.classList.add('hidden');
                });
        }
    }

    checkAllConfirmed();

    document.querySelectorAll('.disassembly-action').forEach((actionBtn) => {
        actionBtn.addEventListener('click', async function (e) {
            const isConfirmed = actionBtn.dataset.confirmed === 'True';
            const response = await sendPostRequest(actionBtn.dataset.url, {
                is_confirmed: !isConfirmed,
            });
            if (response.ok) {
                actionBtn.dataset.confirmed = isConfirmed ? 'False' : 'True';
                actionBtn.classList.toggle('confirmed');
                checkAllConfirmed();
            } else {
                genericErrorAlert();
            }
        });
    });
}

export function initializeDisassemblyComments() {
    const textarea = document.querySelector('#disassemblyComments');
    textarea.addEventListener('change', async function () {
        const response = await sendPatchRequest(textarea.dataset.url, {
            comments: textarea.value,
        });
        if (!response.ok) {
            genericErrorAlert();
        }
    });
}

export function initializeDisassemblySidePanel() {
    const panel = document.querySelector('#disassemblySidePanel');

    panel.querySelectorAll('.disassembly-part').forEach((part) => {
        part.addEventListener('click', async function (e) {
            if (
                [
                    'part-comments-btn',
                    'part-comments',
                    'comments-wrapper',
                ].includes(e.target.className)
            )
                return;
            const response = await sendPostRequest(part.dataset.url);
            const data = await response.json();

            part.classList.remove(part.dataset.status);
            part.classList.add(data.status);
            part.dataset.status = data.status;
            part.querySelector('.status').textContent = data.status_name;

            if (data.status === 'N') {
                part.querySelector('.disassembly-checkbox').checked = false;
            } else {
                part.querySelector('.disassembly-checkbox').checked = true;
            }

            if (['N', 'D'].includes(data.status)) {
                part.querySelector('.part-comments-btn').classList.add(
                    'hidden',
                );
            } else {
                part.querySelector('.part-comments-btn').classList.remove(
                    'hidden',
                );
            }
        });
        const comments = part.querySelector('.part-comments');
        comments.addEventListener('change', async function () {
            const response = await sendPatchRequest(comments.dataset.url, {
                comments: comments.value,
            });
            if (!response.ok) {
                genericErrorAlert();
            }
        });
    });
}
