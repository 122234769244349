import { TabulatorFull as Tabulator } from 'tabulator-tables';
import { debounce, toggleAreaExpanded } from '../utils';
import RowContextMenuButtonColumn from './formatters/RowContextMenuButtonColumn';

export function initializeTable({
    tableElement = '',
    filtersElement = '',
    tabsElement = '',
    ajaxURL = '',
    columns = [],
    rowContextMenu = null,
    height = '600px',
    maxHeight = '90vh',
    rowFormatter = null,
    rowClickPopup = undefined,
    initialFilter = [],
    initialSort = [],
    layout = 'fitColumns',
} = {}) {
    if (rowContextMenu && Array.isArray(rowContextMenu)) {
        rowContextMenu = rowContextMenu.filter((item) => item);
    }

    if (tabsElement) {
        const activeTab = tabsElement.querySelector('.active');
        if (activeTab) {
            initialFilter.push({
                field: activeTab.dataset.filter,
                type: '=',
                value: activeTab.dataset.value,
            });
        }
    }

    const table = new Tabulator(tableElement, {
        ajaxURL: ajaxURL,
        ajaxURLGenerator: function (url, config, params) {
            params['limit'] = params.size;
            params['offset'] = (params.page - 1) * params.size;
            const urlParams = {
                limit: params.size,
                offset: (params.page - 1) * params.size,
            };
            if (params.sort.length > 0) {
                const order =
                    params.sort[0].dir === 'asc'
                        ? params.sort[0].field
                        : `-${params.sort[0].field}`;
                urlParams['order'] = order.replaceAll('.', '__');
            }
            if (params.filter.length > 0) {
                params.filter.forEach((f) => {
                    if (f.value.start || f.value.end) {
                        if (f.value.start) {
                            urlParams[`${f.field.replaceAll('.', '__')}__gte`] =
                                f.value.start;
                        }
                        if (f.value.end) {
                            urlParams[`${f.field.replaceAll('.', '__')}__lte`] =
                                f.value.end;
                        }
                    } else {
                        urlParams[f.field.replaceAll('.', '__')] = f.value;
                    }
                });
            }
            const urlParamsString = new URLSearchParams(urlParams).toString();
            this.externalEvents.dispatch(
                'ajaxURLGenerated',
                url,
                urlParamsString,
            );
            return url + '?' + urlParamsString;
        },
        ajaxResponse: function (url, params, response) {
            this.externalEvents.dispatch('ajaxResponseLoaded', response);
            return {
                last_page: Math.ceil(response.count / params.size),
                last_row: response.count,
                data: response.items,
            };
        },
        height: height,
        minHeight: '15vh',
        maxHeight: maxHeight,
        layout: layout,
        progressiveLoad: 'scroll',
        paginationSize: 50,
        filterMode: 'remote',
        initialFilter: initialFilter,
        initialSort: initialSort,
        sortMode: 'remote',
        selectableRows: false,
        columns: columns,
        rowContextMenu: rowContextMenu,
        rowFormatter: rowFormatter,
        rowClickPopup: rowClickPopup,
        placeholder: function () {
            const activeFiltersCount = this.getFilters().length;
            if (
                activeFiltersCount === 0 ||
                (tabsElement && activeFiltersCount === 1)
            ) {
                return gettext('Brak danych');
            }
            const container = document.createElement('div');
            container.className = 'tabulator-placeholder-contents';
            container.textContent = gettext('Brak pasujących danych');
            const clearButton = document.createElement('button');
            clearButton.className = 'clear-btn';
            clearButton.textContent = gettext('Wyczyść filtry');
            const that = this;
            clearButton.addEventListener('click', function () {
                if (tabsElement) {
                    const activeTab = tabsElement.querySelector('.active');
                    that.setFilter([
                        {
                            field: activeTab.dataset.filter,
                            type: '=',
                            value: activeTab.dataset.value,
                        },
                    ]);
                } else {
                    that.clearFilter();
                }
                filtersElement.querySelectorAll('input').forEach((input) => {
                    input.value = '';
                });
            });
            container.appendChild(clearButton);
            return container;
        },
    });

    if (filtersElement) {
        filtersElement.querySelectorAll('input').forEach((input) => {
            const setFilters = debounce((e) => {
                const filters = [];
                if (tabsElement) {
                    const activeTab = tabsElement.querySelector('.active');
                    filters.push({
                        field: activeTab.dataset.filter,
                        type: '=',
                        value: activeTab.dataset.value,
                    });
                }
                if (filtersElement) {
                    filtersElement
                        .querySelectorAll('input')
                        .forEach((input) => {
                            if (input.value) {
                                filters.push({
                                    field: input.name,
                                    type: '=',
                                    value: input.value,
                                });
                            }
                        });
                }
                table.setFilter(filters);
            });

            input.addEventListener('keyup', function (e) {
                setFilters(e);
            });
            input.addEventListener('paste', function (e) {
                setFilters(e);
            });
        });
    }

    if (tabsElement) {
        tabsElement.querySelectorAll('.tab').forEach((tab) => {
            tab.addEventListener('click', function () {
                const activeTab = tabsElement.querySelector('.active');
                if (activeTab === tab) {
                    return;
                }
                activeTab.classList.remove('active');
                tab.classList.add('active');
                table.setFilter(tab.dataset.filter, '=', tab.dataset.value);
                filtersElement.querySelectorAll('input').forEach((input) => {
                    input.value = '';
                });
            });
        });
    }
    return table;
}

export function initializeTableFilter() {
    const filter_btn = document.querySelector('.filter-btn');

    filter_btn.addEventListener('click', (e) => {
        toggleAreaExpanded(filter_btn);
        filter_btn.classList.toggle('active');
    });
}

export default {
    initializeTable,
    initializeTableFilter,
};
