import 'choices.js/public/assets/styles/choices.min.css';
import '@fortawesome/fontawesome-free/css/all.css';
import '../sass/project.scss';
import { initializeTable, initializeTableFilter } from './tables/tables';
import initializeManualsTable from './tables/definitions/manuals-table';
import utils from './utils';
import alerts from './alerts';
import { DateTime } from 'luxon';
import {
    initializeFormsets,
    setupFormListeners,
    initializeSelect,
    initializePhotoLayout,
    initializeBannerForm,
    initializeStepSelect,
    initializeOrdering,
    initializeDeleteButtons,
    initializePublishButton,
} from './manuals/form';
import {
    initializeDisassemblyActions,
    initializeDisassemblyComments,
    initializeDisassemblySidePanel,
} from './disassembly/form';
import initializeDisassembliesTable from './tables/definitions/disassemblies-table';
window.tables = {
    initializeTable: initializeTable,
    initializeManualsTable: initializeManualsTable,
    initializeTableFilter: initializeTableFilter,
    initializeDisassembliesTable: initializeDisassembliesTable,
};

window.alerts = {
    initializeAlert: alerts.initializeAlert,
    addAlert: alerts.addAlert,
};

window.DateTime = DateTime;

window.manuals = {
    setupFormListeners,
    initializeFormsets,
    initializePhotoLayout,
    initializeBannerForm,
    initializeStepSelect,
    initializeOrdering,
    initializeDeleteButtons,
    initializePublishButton,
};

window.disassembly = {
    initializeDisassemblyActions,
    initializeDisassemblyComments,
    initializeDisassemblySidePanel,
};

window.forms = {
    initializeSelect,
};

window.utils = {
    toggleAreaExpanded: utils.toggleAreaExpanded,
    hideOnClickOutside: utils.hideOnClickOutside,
};

const loadedEvent = new Event('bundleLoaded');
document.dispatchEvent(loadedEvent);

utils.handleMobileMenu();
utils.initializeClock();
